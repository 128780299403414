import { UserHelper } from "../ClaimsGate/UserHelper";
import { Claim, Variable } from "@claimsgate/core-types";
import { ClaimDataService, UserDataService } from "@/helpers/ClaimsGate/DataService";
import { getFunnelVariables } from "@claimsgate/core";
import { getFirebaseBackend } from "@/authUtils";

function resolveVariable(
  variableNames: string | string[],
  funnelVariables: Array<Variable>,
  claimData: Claim,
  userData?: any,
  funnelTitle?: string,
  workspaceName?: string
): string {
  if (typeof variableNames === "string") {
    variableNames = [variableNames];
  }
  console.log("resolving variableNames", variableNames);
  for (const variableName of variableNames) {
    const [name, format] = variableName.split(":");

    // Check if the name is a quoted string (constant)
    if (name.startsWith('"') && name.endsWith('"')) {
      return name.slice(1, -1); // Remove quotes and return as-is
    }

    // Handle special variables
    if (name === "funnelTitle") {
      return `<b>${funnelTitle}</b>`;
    } else if (name === "workspaceName") {
      return `<b>${workspaceName}</b>`;
    }

    // Check user data first
    if (userData[name]) {
      return `<b>${userData[name]}</b>`;
    }

    const variable = funnelVariables.find((v) => v.field === name);
    if (variable) {
      const variableValue = claimData[variable.id];

      if (variableValue !== undefined && variableValue !== null && variableValue !== "") {
        // Handle different variable types
        if (variable.type === "boolean") {
          return variableValue ? "<b>Yes</b>" : "<b>No</b>";
        } else if (variable.type === "number") {
          const numValue = parseFloat(variableValue);
          if (isNaN(numValue)) {
            return `<b>${variableValue}</b>`;
          } else {
            const formattedValue = numValue.toLocaleString();
            if (format === "currency") {
              return `<b>£${formattedValue}</b>`;
            } else {
              return `<b>${formattedValue}</b>`;
            }
          }
        } else {
          return `<b>${variableValue}</b>`;
        }
      }
    }
  }

  return "_";
}

/** Templates a given piece of text using the claim data and user data available in the provided component's state */
export function templateText<
  T extends {
    claimId: string;
    userId: string;
    claimDataService: ClaimDataService;
    userService: UserHelper;
    userDataService: UserDataService;
    isBuilder: boolean;
    funnelVariables: Array<Variable>;
    funnelTitle: string;
    workspaceName: string;
  } & Vue
>(text: string, state: T): string {
  // If we are on the builder just return the original text
  if (state.isBuilder) {
    return text;
  }

  if (!state.userDataService || !state.claimDataService || !state.funnelVariables) {
    return "";
  }

  let templatedText = text;

  const userData = state.userDataService.getCache();
  const claimData = state.claimDataService.getCache();

  // Match both single variables, OR-chained variables, and lists
  const matches = templatedText.match(/(\[.*?\])|{{.*?}}(\|\|{{.*?}})*/g);

  if (matches) {
    for (const match of matches) {
      if (match.startsWith("[") && match.endsWith("]")) {
        // Handle list
        const listItems = match.slice(1, -1).split(",");
        const resolvedItems = listItems
          .map((item) =>
            resolveVariable(
              item.trim().split("||"),
              state.funnelVariables,
              claimData,
              userData,
              state.funnelTitle,
              state.workspaceName
            )
          )
          .filter((item) => item !== null && item !== undefined && item !== "_");
        const replacement = resolvedItems.join(", ");
        templatedText = templatedText.replace(match, replacement || "_");
      } else {
        // Handle single variable or OR-chained variables
        const variableNames = match.split("||").map((v) => v.trim().replace(/^{{|}}$/g, ""));
        const replacement = resolveVariable(
          variableNames,
          state.funnelVariables,
          claimData,
          userData,
          state.funnelTitle,
          state.workspaceName
        );
        templatedText = templatedText.replace(match, replacement);
      }
    }
  }

  return templatedText;
}

/** Statelessly templates a given piece of text using the claim data with OR chaining and list support **/
export async function statelessTemplateClaimText(text: string, claimData: Claim): Promise<string> {
  let templatedText = text;

  const [funnelVariables, _getFunnelVariablesError] = await getFunnelVariables(
    getFirebaseBackend().firestore(),
    claimData.currentFunnelId
  );

  // Match both single variables, OR-chained variables, and lists
  const matches = templatedText.match(/(\[.*?\])|{{.*?}}(\|\|{{.*?}})*/g);

  if (matches) {
    for (const match of matches) {
      if (match.startsWith("[") && match.endsWith("]")) {
        // Handle list
        const listItems = match.slice(1, -1).split(",");
        const resolvedItems = listItems
          .map((item) => resolveVariable(item.trim().split("||"), funnelVariables, claimData))
          .filter((item) => item !== null && item !== undefined && item !== "_");
        const replacement = resolvedItems.join(", ");
        templatedText = templatedText.replace(match, replacement || "_");
      } else {
        // Handle single variable or OR-chained variables
        const variableNames = match.split("||").map((v) => v.trim().replace(/^{{|}}$/g, ""));
        const replacement = resolveVariable(variableNames, funnelVariables, claimData);
        templatedText = templatedText.replace(match, replacement);
      }
    }
  }

  return templatedText;
}
