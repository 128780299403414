export const state = {
  message: "",
  primaryFetchComplete: false,
  callbacks: {},
};

export const getters = {
  getMessage(state) {
    return state.message;
  },
  getCount(state) {
    return state.count;
  },
  getCallbacks(state) {
    return state.callbacks;
  },
};

export const mutations = {
  registerCallback(state, { key, func }) {
    state.callbacks = {
      ...state.callbacks,
      [key]: { key, func, running: false },
    };
  },
  deregisterCallback(state, key) {
    // eslint-disable-next-line no-unused-vars
    const { [key]: _, ...remainingCallbacks } = state.callbacks;
    state.callbacks = remainingCallbacks;
  },
  updateCallbackRunning(state, { key, running }) {
    state.callbacks[key].running = running;
  },
};

export const actions = {
  updateMessage({ commit }, message) {
    commit("setMessage", message);
  },
  updateCount({ commit }, count) {
    commit("setCount", count);
  },
  increment({ commit }) {
    commit("incrementCount");
  },
  registerCallback({ commit, state }, { key, func }) {
    const id = state.nextCallbackId;
    commit("registerCallback", { key, func });
    return id;
  },
  deregisterCallback({ commit }, id) {
    commit("deregisterCallback", id);
  },
  updateCallbackRunning({ commit }, { key, running }) {
    commit("updateCallbackRunning", { key, running });
  },
};
