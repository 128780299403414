<script lang="ts">
import { gmaps } from "x5-gmaps";
import Vue from "vue";
import { Props } from "@/types";
import BlockInputWrapper from "@/components/shared/blocks/inputwrapper.vue";

import { inputProps } from "@/helpers/ClaimsGate/blocks/inputProps";
const inputPropsValue: Props.BlockInput = inputProps({});
export default Vue.extend({
  name: "BlockPlaces",
  components: {
    BlockInputWrapper,
  },
  props: {
    ...inputPropsValue,

    existingValue: {
      type: [String, Object],
      default: null,
    },

    returnCountry: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // Search data
      autocompleteElement: {} as any,
      place: null,
      x: null,
    };
  },
  methods: {
    update() {
      const place = this.autocompleteElement?.getPlace();
      console.log(place);
      this.place = this.extractAddressComponents(place);

      if (!this.place) return;

      this.$emit("placeSelected", this.place);
      this.$emit("update:existingValue", this.formatAddress(this.place));
    },

    // Function to extract address components from a Places API response
    extractAddressComponents(place) {
      if (!place?.address_components) return null;

      // Initialize address components
      let addressLine1 = "";
      let city = "";
      let postcode = "";
      let country = "";
      try {
        // Iterate over each address component in the response
        place.address_components.forEach((component) => {
          // Check if the component type is either 'street_number' or 'route' and append to addressLine1
          if (component.types.includes("street_number") || component.types.includes("route")) {
            addressLine1 += `${component.long_name} `;
          }
          // Check if the component type is 'postal_town' and assign to city
          if (component.types.includes("postal_town")) {
            city = component.long_name;
          }
          // Check if the component type is 'locality' and city is not yet assigned, then assign to city
          if (component.types.includes("locality") && city === "") {
            city = component.long_name;
          }
          // Check if the component type is 'postal_code' and assign to postcode
          if (component.types.includes("postal_code")) {
            postcode = component.long_name;
          }
          // Check if the component type is 'country' and assign to country
          if (component.types.includes("country")) {
            country = component.long_name;
          }
        });

        // Return the extracted address components
        return { addressLine1: addressLine1.trim(), city, postcode, country };
      } catch (error) {
        console.error("Error extracting address components:", error);
        return null;
      }
    },

    formatAddress(addressObj) {
      if (!addressObj || typeof addressObj !== "object") {
        return "";
      }
      const { addressLine1, city, postcode, country } = addressObj;
      return [addressLine1, city, postcode, country].filter(Boolean).join(", ");
    },
  },
  mounted() {
    gmaps().then((maps) => {
      this.autocompleteElement = new maps.places.Autocomplete((this.$refs?.autocomplete as { $el: any }).$el);

      this.autocompleteElement?.addListener("place_changed", this.update);
    });
  },
});
</script>

<style>
.pac-container {
  z-index: 9999;
}

.pac-item {
  padding: 5px;
  font-family: "Poppins" !important;
  font-size: 16.25px !important;
}
.pac-item-query {
  font-family: "Poppins" !important;
  font-size: 16.25px !important;
}
</style>

<template>
  <BlockInputWrapper :info="info" :padding="padding" :label="label" :invalidFeedback="invalidFeedback" :state="state">
    <b-form-input
      :value="typeof existingValue === 'object' ? formatAddress(existingValue) : existingValue"
      ref="autocomplete"
      id="autocomplete"
      size="lg"
      placeholder="Enter Your Address"
      class="block-input"
      :state="state"
      data-cy="places-input"
    ></b-form-input>
  </BlockInputWrapper>
</template>
