var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('BlockInputWrapper',_vm._b({},'BlockInputWrapper',{ padding: _vm.padding, label: _vm.label, info: _vm.info },false),[_vm._l((_vm.inputGroups),function(inputGroup,index){return [_c('b-modal',{key:index + 'group',attrs:{"id":'inputGroupModal' + index + _vm.entityName,"no-close-on-backdrop":true,"no-close-on-esc":true},scopedSlots:_vm._u([{key:"modal-header",fn:function({ close }){return [_c('CardHeader',{attrs:{"heading":_vm.addText,"supportingText":'Please complete the form below'},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('b-button',{staticClass:"p-0 pr-1",attrs:{"size":"lg","variant":"link"},on:{"click":function($event){close();
                _vm.deleteIncompleteEntityAnswer(index);}}},[_c('i',{staticClass:"fas fa-times mx-1"})])]},proxy:true}],null,true)})]}},{key:"modal-footer",fn:function({ cancel }){return [_c('div',{staticClass:"d-flex w-100"},[_c('div',{staticClass:"grow"},[_c('b-button',{staticClass:"w-100 mr-1",attrs:{"size":"md","variant":"light"},on:{"click":function($event){cancel();
                _vm.deleteIncompleteEntityAnswer(index);}}},[_vm._v(" Cancel ")])],1),_c('div',{staticClass:"grow"},[_c('b-button',{staticClass:"w-100 ml-1",attrs:{"size":"md","variant":"primary "},on:{"click":function($event){return _vm.saveEntity(index)}}},[(_vm.uiToggles.isEntityValidating)?[_c('b-spinner',{staticClass:"spinner-border-sm",attrs:{"role":"status"}})]:[_c('b-icon',{attrs:{"icon":"plus"}}),_vm._v(" "+_vm._s(_vm.buttonText)+" ")]],2)],1)])]}}],null,true)},[_vm._l((inputGroup),function(input,inputIndex){return [(input.blockType !== 'BlockPlaces')?[_c(_vm.componentList[input.blockType],_vm._b({key:inputIndex + 'element' + input.field,ref:input.field,refInFor:true,tag:"component",attrs:{"answer":_vm.inputAnswer[index][_vm.getFieldId(input.field)],"padding":inputIndex === 0 ? '5' : '20'},on:{"update:answer":function($event){_vm.$set(_vm.inputAnswer[index], _vm.getFieldId(input.field), $event)}}},'component',input.props,false,true))]:[_c(_vm.componentList[input.blockType],_vm._b({key:inputIndex + 'element' + input.field,ref:input.field,refInFor:true,tag:"component",attrs:{"answer":_vm.inputAnswer[index][_vm.getFieldId(input.field)],"existingValue":_vm.inputAnswer[index][_vm.getFieldId(input.field)],"padding":inputIndex === 0 ? '5' : '20'},on:{"update:answer":function($event){_vm.$set(_vm.inputAnswer[index], _vm.getFieldId(input.field), $event)},"update:existingValue":function($event){_vm.$set(_vm.inputAnswer[index], _vm.getFieldId(input.field), $event)},"update:existing-value":function($event){_vm.$set(_vm.inputAnswer[index], _vm.getFieldId(input.field), $event)},"placeSelected":(e) => _vm.handlePlaceSelected(e, _vm.inputAnswer, index)}},'component',input.props,false,true))]]})],2),[_c('b-card',{key:'existingElement' + index,class:['p-0', 'm-0', _vm.roundingClass(index), _vm.getValidationClasses(index)]},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"grow"},[_c('h5',{staticClass:"mb-0"},[(
                  _vm.inputAnswer[index][_vm.getFieldId('title')] &&
                  _vm.inputAnswer[index][_vm.getFieldId('firstName')] &&
                  _vm.inputAnswer[index][_vm.getFieldId('lastName')]
                )?[_c('span',{staticClass:"font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.inputAnswer[index][_vm.getFieldId("title")])+" "+_vm._s(_vm.inputAnswer[index][_vm.getFieldId("firstName")])+" "+_vm._s(_vm.inputAnswer[index][_vm.getFieldId("lastName")])+" ")])]:[_c('span',{staticClass:"font-weight-semibold"},[_vm._v(" "+_vm._s(_vm.entityName)+" "+_vm._s(index + 1))])]],2)]),(index > 0)?_c('div',[_c('b-button',{staticClass:"mr-1",attrs:{"size":"md","variant":"outline-light text-grey-600"},on:{"click":function($event){return _vm.removeEntity(index)}}},[_vm._v(" Remove ")])],1):_vm._e(),_c('div',[_c('b-button',{staticClass:"bg-primary-50 text-primary-700 border-primary-50 bg-hover-primary-100 font-weight-semibold",attrs:{"size":"md","variant":"outline-primary"},on:{"click":function($event){return _vm.editEntity(index)}}},[_vm._v(" Edit ")])],1)])])]]}),_c('b-button',{attrs:{"size":"lg","variant":"link"},on:{"click":function($event){return _vm.addEntity()}}},[_c('b-icon',{attrs:{"icon":"plus"}}),_vm._v(" "+_vm._s(_vm.addText)+" ")],1),(_vm.isValid === false)?[(_vm.inputAnswer.length <= 1)?_c('p',{staticClass:"mb-0 text-danger text-sm"},[_vm._v("This field is required")]):_c('p',{staticClass:"mb-0 text-danger text-sm"},[_vm._v("All items must be completed or removed")])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }