import { blocks } from "./blocks";
import { getUserHelper } from "@/helpers/ClaimsGate/UserHelper";
import { UserDataService, ClaimDataService } from "@/helpers/ClaimsGate/DataService";
import { FunnelsService } from "@claimsgate/core";
import { getFirebaseBackend } from "@/authUtils";
import { AddressListEntity } from "@claimsgate/core-types";
import { VariableService } from "@/helpers/ClaimsGate/VariableService";
export function getData(state: Vue) {
  return {
    uiToggles: {
      show: {
        addressSearch: true,
        addressSelect: false,
        submitButton: false,
      },
      loaders: {
        isSearchingForAddress: false,
      },
    },
    uiMessages: {
      addressNotSearched: "Please search for the address you want to add.",
      addressPostcodeMissing: "Please enter the postcode",
      postcodeTooShort: "UK Postcode name must be at least 5 characters long",
      addressNotFound: "We were unable to find your address",
      addressNotSelected: "Please select your address",
    },
    addresses: null as Array<AddressListEntity> | null,
    funnelsService: new FunnelsService(getFirebaseBackend().firestore()),
    variablesService: new VariableService(),
    BlockInputs: {
      ...blocks.buttons,
      ...blocks.inputs,
    },
    internationalPlace: {
      addressLine1: "",
      city: "",
      postcode: "",
      country: "",
      invalidFeedback: "",
      state: null,
      answer: "",
    },

    userService: getUserHelper(),
    userDataService: undefined as UserDataService,
    userId: undefined as string,
    claimId: state.$route.params.claimId as string,
    pageId: state.$route.params.pageId as string,
    funnelId: state.$route.params.funnelId as string,
    claimDataService: undefined as ClaimDataService,
  };
}
